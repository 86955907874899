<template>
	<div>
		<img src="../../../assets/imges/index/1.png" class="headerimg" />
		<div class="LatestActivity">

			<div class="header">
				<span class="Selected activity">秒杀活动</span>
				<span class="bond" @click="toLatestActivity">限量神劵</span>
			</div>
			<div  class="card">
				<div class="cardlist" v-for="(item,index) in activitylist" :key='index'>
					<div class="cardback">
						<div class="title" v-if="item.couponsRecipientTypeId == 3">新人专享</div>
						<div class="activityinfo">
							<div class="cardname">{{item.name}}</div>
							<div class="cardinfo">{{item.seckillDescription}}</div>
							<div class="cardtag">
								<span v-if="item.tagOne">{{item.tagOne}}</span>
								<span v-if="item.tagThree">{{item.tagThree}}</span>
								<span v-if="item.tagTwo">{{item.tagTwo}}</span>
							</div>
							<div class="cardtime">
								<span class="endtime">距结束时间</span>
								<countdown style="display: inline-block;" :time='item.endDate'></countdown>
								<!-- <span class="time">10</span>:<span class="time">10</span>:<span class="time">10</span> -->
								<span class="price">￥<span class="pricenum"></span>{{item.seckillPrice}}/年</span>
								<span class="originalprice">原价￥{{item.salePrice}}元</span>
							</div>
						</div>
					</div>
					<div class="activitybutton">
						<el-button type="primary" plain>立即购买</el-button>
					</div>
				</div>
				<div class="cardlist2"></div>
				<div class="cardlist2"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import countdown from '../../../components/countdown.vue'
	import {TimeLimitSeckillsList} from '../../../api/index.js'
	export default {
		name: "Home",
		components:{
			countdown
		},
		data() {
			return {
				type: "activity",
				activitylist: [],
			};
		},
		mounted() {},
		created() {
			TimeLimitSeckillsList().then(res=>{
				this.activitylist = res.data
			})
		},
		methods: {
			toLatestActivity() {
				this.$router.push('LatestActivity')
			},
		},
	};
</script>

<style scoped lang="less">
	.el-button--primary.is-plain {
		color: #409eff;
		background: #fff;
		border-color: #b3d8ff;
	}

	.el-button--primary.is-plain:hover {
		color: #fff;
		background: #409eff;
		border-color: #409eff;
	}

	.LatestActivity {
		width: 80%;
		margin: 0 auto;

		.header {
			text-align: center;
			font-size: 28px;
			font-family: SourceHanSansCN-Bold, SourceHanSansCN;
			font-weight: bold;
			border-bottom: 1px solid #979797;
			cursor: default;
			height: 120px;
			line-height: 120px;

			span {
				padding-bottom: 16px;
			}

			.bond {
				margin-left: 154px;
			}

			.Selected {
				border-bottom: 8px solid #2f7ffc;
				color: #2f7ffc;
			}
		}

		.card {
			padding: 111px 0;
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.cardlist {
				margin-bottom: 70px;

				.cardback {
					height: 231px;
					width: 430px;
					box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
					border-radius: 10px;
					background-image: url(../../../assets/activity/background.png);
					background-size: cover;

					.title {
						width: 108px;
						height: 33px;
						background: #d92c54;
						border-radius: 0px 11px 0px 11px;
						color: #fff;
						font-size: 16px;
						text-align: center;
						line-height: 30px;
						float: right;
					}

					.activityinfo {
						padding-top: 31px;
						padding-left: 30px;

						.cardname {
							font-size: 28px;
							font-family: Medium;
							font-weight: 500;
							color: #3d88fc;
						}

						.cardinfo {
							font-size: 16px;
							font-family: Medium;
							font-weight: 500;
							color: #9e9e9e;
							margin-top: 26px;
						}

						.cardtag {
							margin-top: 8px;

							span {
								color: #2f7ffc;
								border-radius: 4px;
								background: #eef4ff;
								font-size: 12px;
								line-height: 18px;
								padding: 2px 5px;
								margin-right: 14px;
							}
						}

						.cardtime {
							// margin-top: 37px;
							font-size: 10px;

							.endtime {
								font-size: 14px;
								font-family: Medium;
								font-weight: 500;
								color: #333330;
								margin-right: 6px;
							}

							.time {
								padding: 2px;
								background: #ff825c;
								border-radius: 1px;
								font-size: 10px;
								font-family: Medium;
								font-weight: 500;
								color: #ffffff;
								margin: 0 2.5px;
							}

							.price {
								font-size: 12px;
								font-family: SourceHanSansCN-Bold, SourceHanSansCN;
								font-weight: bold;
								color: #d92c54;
								margin-left: 69px;

								.pricenum {
									font-size: 18px;
								}
							}

							.originalprice {
								font-size: 10px;
								font-family: Medium;
								font-weight: 500;
								color: #9e9e9e;
								text-decoration: line-through;
								margin-left: 14px;
							}
						}
					}

					.bondinfo {
						padding-top: 33px;
						padding-left: 47px;

						.cardname {
							font-size: 28px;
							font-family: Medium;
							font-weight: 500;
							color: #4c4435;
						}

						.cardinfo {
							margin-top: 32px;
							display: flex;

							.price {
								font-size: 32px;
								font-family: SourceHanSansCN-Bold, SourceHanSansCN;
								font-weight: bold;
								color: #d92c54;
								line-height: 48px;
								padding: 19px 22px 19px 0;
							}

							.info {
								font-size: 18px;
								font-family: Medium;
								font-weight: 500;
								color: #2f7ffc;
								line-height: 37px;
								float: right;
								padding-left: 31px;
								border-left: 1px solid #d9d9d9;
							}
						}

						.time {
							font-size: 14px;
							font-family: Medium;
							font-weight: 500;
							color: #9e9e9e;
							float: right;
							padding-right: 32px;
						}
					}
				}

				.activitybutton {
					margin-top: 23px;
					float: right;
				}
			}
		}
	}
	
	.cardlist2{
		height: 8px;
		width: 430px;
	}
</style>
