<template>
<div class="Date">
       <span class="time">{{h}}</span>:<span class="time">{{m}}</span>:<span class="time">{{s}}</span>
      </div>
</template>
<script>
export default {
  name: 'Date',
  data() {
    return {
      d: '',
      h: '',
      m: '',
      s: '',
    }
  },
  props:{
	time:String  
  },
  created() {
    this.countTime()
	
  },
  methods: {
    countTime: function() {
      //获取当前时间
      var date = new Date()
      var now = date.getTime()
      //设置截止时间
      var endDate = new Date(this.$props.time)
      var end = endDate.getTime()
      //时间差
      var leftTime = end - now
      //定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        this.d = Math.floor(leftTime / 1000 / 60 / 60 / 24)//天数我没用到，暂且写上
        this.h = this.formatNumber(Math.floor((leftTime / 1000 / 60 / 60) % 24))
        this.m = this.formatNumber(Math.floor((leftTime / 1000 / 60) % 60))
        this.s = this.formatNumber(Math.floor((leftTime / 1000) % 60))
      }
      //递归每秒调用countTime方法，显示动态时间效果
      setTimeout(this.countTime, 1000)
    },
	 formatNumber(n){
	  n = n.toString()
	  return n[1] ? n : '0' + n
	}
  },
}
</script>
<style scoped lang="less">
	.time {
		padding: 2px;
		background: #ff825c;
		border-radius: 1px;
		font-size: 10px;
		font-family: Medium;
		font-weight: 500;
		color: #ffffff;
		margin: 0 2.5px;
	}
</style>